<template>
  <section class="section">
    <h2 class="title">
      {{ $t("route.forwarders") }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t("subtitle.forwarders") }}
        </div>
        <div class="level-right">
          <dropdown style="margin-right: 5px">
            <a slot="v" class="button is-default">
              <octicon :icon="desktopDownload" />
              <span>Download Installer</span>
            </a>
            <a
              class="dropdown-item is-primary forwarders-download"
              @click.prevent="download('windows', 64)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M0 80v160h224V52zM256 48v192h256V16zM256 272v192l256 32V272zM0 272v160l224 28V272z"
                />
              </svg>
              Windows 64Bit
            </a>
            <a
              class="dropdown-item is-primary forwarders-download"
              @click.prevent="download('linux', 64)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 305">
                <path
                  d="M275 245c-9-4-13-9-13-16 1-8-4-15-6-17 1-5 5-23 0-39-6-17-24-43-42-69-8-10-8-21-8-34-1-13-1-27-8-43-8-17-23-27-42-27-11 0-23 4-32 10-18 12-16 40-14 58v7c1 18 0 27-1 30l-9 13-13 18c-5 8-9 19-13 30l-8 20c-5 9-4 17-3 20l-7 9c-2 6-8 9-19 11-6 1-9 3-11 7-3 4-1 10 0 15 2 6 1 9-1 16l-2 6c-1 3-1 6 1 8 4 7 17 9 30 11 8 1 16 4 24 7l24 7h4c11 0 16-8 18-11l33-4c14 0 28 3 32 4 2 2 5 8 10 11l11 2c5 0 13-1 20-8a174 174 0 0143-27c6-3 10-9 10-14-1-5-4-9-8-11zm-151-1c-1-6-8-12-17-19-7-6-16-12-18-17-5-12-1-31 5-41 4-5 6-13 9-20 2-8 5-16 8-20 5-6 10-16 11-25 4 4 12 10 18 10h3l18-9 15-8c5 6 31 64 33 82 2 15 0 27-1 31h-3c-7 0-9 4-10 6l-1 30c-3 4-16 19-35 22a150 150 0 01-32 1l-9-11c3-2 7-5 6-12zm12-180l-1 1v-2c-1-6-5-11-9-11h-1c-3 1-5 3-6 6 1-7 4-11 9-11 4 0 8 6 8 14v3zm38 5l1-5c0-7-5-12-11-12-5 0-10 5-10 12v1l-1-6c0-8 5-15 12-15 6 0 12 7 12 15 0 4-1 7-3 10zm-5 16l-2 2-5 2-1 1c-5 3-16 10-19 10l-6-3-2-1c-5-3-8-7-9-8l8-7c5-4 9-6 11-6l14 4 5 2 6 4zm36 186a148 148 0 004-48c0 4 1 14 9 17l7 1c8 0 16-3 19-7l5-6v3c0 7 3 16 10 19l1 1c2 1 8 4 8 6l-12 7c-9 4-20 10-24 15-7 7-14 11-19 11h-1c-5-2-9-9-7-19zM40 245l-1-5c1-2 7-3 10-4 4-1 8-1 11-3s5-6 7-10c1-2 2-5 4-6 3 0 9 6 12 11l5 8c5 9 13 22 17 26 3 4 9 11 8 17-1 5-7 9-8 10h-2c-8 0-23-6-31-9l-1-1c-4-2-12-3-19-4l-15-3c-1-2 1-5 2-9l2-7-1-11z"
                />
              </svg>
              Linux 64Bit
            </a>
          </dropdown>
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t("newForwarder") }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table ref="table" :items="items" :theme="$_ui_theme_tables">
        <data-column field="name" :label="$t('name')" width="15%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="enabled" :label="$t('status')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value" class="tag is-success is-light">{{
              $t("enabled")
            }}</span>
            <span v-else class="tag is-warning is-light">{{
              $t("disabled")
            }}</span>
          </template>
        </data-column>
        <data-column
          :label="$t('activeModules')"
          width="25%"
          :filterable="false"
          :sortable="false"
        >
          <template slot-scope="record">
            <span
              v-if="record.item.windows_event_log.enabled"
              class="tag is-success is-light"
              >Windows Event Log</span
            >
            <span
              v-if="record.item.file_log.enabled"
              class="tag is-success is-light"
              >File Log</span
            >
            <span
              v-if="record.item.resource_monitor.enabled"
              class="tag is-success is-light"
              >Resource Monitor</span
            >
          </template>
        </data-column>
        <data-column field="last_update" :label="$t('lastEvent')" width="15%">
          <template slot-scope="props">
            {{
              props.value
                ? format(new Date(props.value), "dd/MM/yyyy HH:mm:ss")
                : "None"
            }}
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          class="has-text-centered"
          :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t("edit") }}</span>
              </button>
              <button
                class="button is-text"
                @click.prevent="destroy(props.item)"
              >
                <octicon :icon="x" /> <span>{{ $t("delete") }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.name || $t("newForwarder") }}
        </div>

        <form @submit.prevent="submit">
          <template v-if="!expanded">
            <div class="field">
              <label class="label">{{ $t("name") }}</label>
              <div class="control">
                <input v-model="item.name" class="input" type="text" required />
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t("description") }}</label>
              <div class="control">
                <input v-model="item.description" class="input" type="text" />
              </div>
            </div>

            <label class="label">{{ $t("token") }}</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input v-model="item.token" class="input" type="text" />
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  @click.prevent="randomToken"
                >
                  <octicon :icon="sync" /> <span>{{ $t("random") }}</span>
                </button>
              </div>
              <div class="control">
                <button type="button" class="button" @click.prevent="copyToken">
                  <octicon :icon="clippy" /> <span>{{ $t("copy") }}</span>
                </button>
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t("status") }}</label>
              <input
                id="enabled"
                v-model="item.enabled"
                type="checkbox"
                name="enabled"
                class="switch"
              />
              <label for="enabled">{{
                item.enabled ? $t("enabled") : $t("disabled")
              }}</label>
            </div>
          </template>

          <div class="has-text-centered has-text-grey-light">
            <p>
              <a
                href="#"
                class="button is-text"
                @click.prevent="expanded = !expanded"
              >
                <octicon v-if="expanded" :icon="chevronUp" />
                <octicon v-else :icon="chevronDown" />
                <span v-text="expanded ? $t('details') : $t('modules')" />
              </a>
            </p>
          </div>

          <tabs v-if="expanded" :theme="$_ui_theme_tabs">
            <tab
              title="Windows Event Log"
              style="padding-left: 0; padding-right: 0"
            >
              <div class="field">
                <label class="label">{{ $t("status") }}</label>
                <input
                  id="wel_enabled"
                  v-model="item.windows_event_log.enabled"
                  type="checkbox"
                  name="enabled"
                  class="switch"
                />
                <label for="wel_enabled">{{
                  item.windows_event_log.enabled
                    ? $t("enabled")
                    : $t("disabled")
                }}</label>
              </div>

              <div class="field" @keydown.enter.prevent>
                <label class="label">{{ $t("types") }}</label>
                <v-select
                  v-model="item.windows_event_log.types"
                  :options="options"
                  :multiple="true"
                />
              </div>
              <div
                class="field"
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <input
                  class="input"
                  v-model="newOption"
                  style="padding-right: 5px; width: 80%"
                />
                <button class="button" @click="addOption">Add file</button>
              </div>

              <div class="field">
                <label class="label">{{ $t("index") }}</label>
                <div class="field">
                  <p class="control">
                    <span class="select is-fullwidth">
                      <select v-model="item.windows_event_log.index" required>
                        <option disabled value="">
                          {{ $t("selectDefault") }}
                        </option>
                        <option
                          v-for="e in indexNames"
                          :key="e.id"
                          :value="e.id"
                        >
                          {{ e.name }}
                        </option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </tab>
            <tab title="File Log" style="padding-left: 0; padding-right: 0">
              <div class="field">
                <label class="label">{{ $t("status") }}</label>
                <input
                  id="file_enabled"
                  v-model="item.file_log.enabled"
                  type="checkbox"
                  name="enabled"
                  class="switch"
                />
                <label for="file_enabled">{{
                  item.file_log.enabled ? $t("enabled") : $t("disabled")
                }}</label>
              </div>

              <label class="label">{{ $t("streams") }}</label>
              <tabs :show.sync="streamActive" :theme="$_ui_theme_tabs">
                <tab
                  v-for="(stream, index) in item.file_log.streams"
                  :key="index"
                  :title="index + 1 + ''"
                >
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t("paths") }}</label>
                        <div class="control">
                          <textarea
                            :value="stream.paths.join('\n')"
                            class="textarea"
                            rows="2"
                            @input="streamPathParse($event, index)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t("excludes") }}</label>
                        <div class="control">
                          <textarea
                            :value="stream.excludes.join('\n')"
                            class="textarea"
                            rows="2"
                            @input="streamExcludeParse($event, index)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <label class="label">{{ $t("index") }}</label>
                      <div class="field">
                        <p class="control">
                          <span class="select is-fullwidth">
                            <select v-model="stream.index" required>
                              <option disabled value="">
                                {{ $t("selectDefault") }}
                              </option>
                              <option
                                v-for="e in indexNames"
                                :key="e.id"
                                :value="e.id"
                              >
                                {{ e.name }}
                              </option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <label class="label">{{ $t("sourceType") }}</label>
                      <div class="field">
                        <p class="control">
                          <span class="select is-fullwidth">
                            <select v-model="stream.source_type" required>
                              <option disabled value="">
                                {{ $t("selectDefault") }}
                              </option>
                              <option
                                v-for="e in sourceTypeNames"
                                :key="e.id"
                                :value="e.id"
                              >
                                {{ e.name }}
                              </option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </tab>
                <tab
                  :title="$t('add')"
                  :icon="plus"
                  ghost
                  @click.prevent="addStream"
                />
                <tab
                  v-if="item.file_log.streams && item.file_log.streams.length"
                  :title="$t('remove')"
                  :icon="x"
                  ghost
                  end
                  @click.prevent="removeStream"
                />
              </tabs>
            </tab>
            <tab
              title="Resource Monitor"
              style="padding-left: 0; padding-right: 0"
            >
              <div class="field">
                <label class="label">{{ $t("status") }}</label>
                <input
                  id="rm_enabled"
                  v-model="item.resource_monitor.enabled"
                  type="checkbox"
                  name="enabled"
                  class="switch"
                />
                <label for="rm_enabled">{{
                  item.resource_monitor.enabled ? $t("enabled") : $t("disabled")
                }}</label>
              </div>

              <div class="field">
                <label class="label">{{ $t("index") }}</label>
                <div class="field">
                  <p class="control">
                    <span class="select is-fullwidth">
                      <input
                        class="input"
                        value="resources"
                        type="text"
                        required
                        disabled
                      />
                    </span>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t("intervals") }}</label>
                <div class="field">
                  <div class="control">
                    <input
                      v-model="item.resource_monitor.interval"
                      class="input"
                      type="number"
                      required
                      :disabled="!item.resource_monitor.enabled"
                    />
                  </div>
                </div>
              </div>
            </tab>
          </tabs>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t("save") }}
              </button>
            </div>
            <div class="control">
              <button
                type="button"
                class="button is-link is-light"
                @click.prevent="closeModal"
              >
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
    <modal :show.sync="createVersionModal">
      <box>
        <div slot="header">Upload a new version</div>
        <form ref="addVersionForm" @submit.prevent="handleUploadedAgents">
          <span class="label">Version</span>
          <div class="field">
            <div class="control">
              <input v-model="version" class="input" type="text" required />
            </div>
          </div>
          <div class="field">
            <span class="label">Linux 64bit:</span>
            <input ref="linux64" type="file" class="input" />
          </div>
          <div class="field">
            <span class="label">Linux 32bit:</span>
            <input ref="linux32" type="file" class="input" />
          </div>
          <div class="field">
            <span class="label">Windows 64bit:</span>
            <input ref="windows64" type="file" class="input" />
          </div>
          <div class="field">
            <span class="label">Windows 32bit:</span>
            <input ref="windows32" type="file" class="input" />
          </div>
          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button
                type="submit"
                class="button is-link"
                :disabled="isSavingVersion"
              >
                <div
                  style="all: unset"
                  class="button is-info"
                  :class="{ 'is-loading': isSavingVersion }"
                >
                  Save
                </div>
              </button>
            </div>
            <div class="control">
              <button
                type="button"
                class="button is-link is-light"
                @click="(createVersionModal = false), (isSavingVersion = false)"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
    <h2 class="title">
      Agents
      <div class="level">
        <div class="level-left subtitle">The list of agents installed</div>
      </div>
    </h2>
    <data-table :items="fetchAgents" :theme="$_ui_theme_tables">
      <data-column field="agent_hostname" :label="'Hostname'" width="15%" />
      <data-column field="agent_mac" :label="'MAC Address'" />
      <data-column field="token" :label="'Token'" />
      <data-column field="agent_version" :label="'Version'" width="10%" />
      <data-column field="agent_status" :label="'Status'" width="10%">
        <template slot-scope="props">
          <div v-if="props.value === 'online'" class="tag is-success is-light">
            {{ "online" }}
          </div>
          <div
            v-else-if="props.value === 'offline'"
            class="tag is-danger is-light"
          >
            {{ "offline" }}
          </div>
        </template>
      </data-column>
      <data-column field="ips" :label="'IPs'">
        <template slot-scope="{ value }">
          <div v-if="value">
            <div
              v-for="ip in value.map((obj) => obj.ip)"
              :key="ip"
              class="tag is-success is-light"
            >
              {{ ip }}
            </div>
          </div>
        </template>
      </data-column>
      <data-column field="agent_last_seen" :label="'Last seen'" width="20%">
        <template slot-scope="props">
          {{
            props.value
              ? format(new Date(props.value), "dd/MM/yyyy HH:mm:ss")
              : "None"
          }}
        </template>
      </data-column>
    </data-table>
    <h2 class="title">
      Version
      <div class="level">
        <div class="level-left subtitle">Released versions</div>
        <div class="level-right">
          <button
            class="button is-primary"
            @click.prevent="createVersionModal = true"
          >
            <octicon :icon="plus" /> <span>Upload a new version</span>
          </button>
        </div>
      </div>
    </h2>
    <data-table :items="fetchVersions" :theme="$_ui_theme_tables">
      <data-column field="version" :label="'Tag'" />
      <data-column field="created_at" :label="'Created at'" />
    </data-table>
  </section>
</template>
<script>
import clipboard from "@/clipboard.js";
import { DataColumn, DataTable, Modal, Tab, Tabs } from "@cyradar/ui";
import { format } from "date-fns";
import {
  chevronDown,
  chevronUp,
  clippy,
  desktopDownload,
  pencil,
  plus,
  sync,
  x,
} from "octicons-vue";
import { v4 as uuid } from "uuid";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const nameMap = (names) =>
  names.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

export default {
  components: { DataTable, DataColumn, Modal, Tabs, Tab, vSelect },
  data() {
    return {
      createVersionModal: false,
      version: "",
      isSavingVersion: false,
      linux64: {},
      linux32: {},
      windows64: {},
      windows32: {},
      newOption: "",
      modal: false,
      item: null,
      source_types: [],
      indexes: [],
      streamActive: 0,
      expanded: false,
    };
  },
  computed: {
    plus() {
      return plus;
    },
    x() {
      return x;
    },
    pencil() {
      return pencil;
    },
    chevronDown() {
      return chevronDown;
    },
    chevronUp() {
      return chevronUp;
    },
    sync() {
      return sync;
    },
    clippy() {
      return clippy;
    },
    desktopDownload() {
      return desktopDownload;
    },
    options() {
      return [
        "Security",
        "Application",
        "Setup",
        "System",
        "Windows PowerShell",
      ];
    },
    sourceTypeNames() {
      return nameMap(this.source_types);
    },
    indexNames() {
      return nameMap(this.indexes);
    },
  },
  watch: {
    item(val) {
      if (val && val.token) {
        return;
      }

      this.randomToken();
    },
  },
  mounted() {
    this.loadIndexes();
    this.loadSourceTypes();
  },
  methods: {
    format,
    copyToken() {
      clipboard(this.item.token || "");
    },
    randomToken() {
      const token = uuid();
      if (!token) {
        return;
      }

      this.$set(this.item, "token", token.replace(/-/g, ""));
    },
    download(os, bit) {
      window.location.assign("/forwarder/installer?os=" + os + "&bit=" + bit);
    },
    closeModal() {
      this.modal = false;
    },
    streamPathParse(e, index) {
      const value = e.target.value.split("\n");
      this.item.file_log.streams[index].paths = value;
    },
    streamExcludeParse(e, index) {
      const value = e.target.value.split("\n");
      this.item.file_log.streams[index].excludes = value;
    },
    addStream() {
      if (!this.item.file_log.streams) {
        this.$set(this.item.file_log, "streams", []);
      }

      this.item.file_log.streams.push({
        paths: [],
        excludes: [],
        index: "",
        source_type: "",
      });

      this.streamActive = this.item.file_log.streams.length - 1;
    },
    removeStream() {
      if (!window.confirm(this.$t("rus"))) {
        return;
      }

      this.item.file_log.streams.splice(this.streamActive, 1);
      this.streamActive = 0;
    },
    submit() {
      if (this.item.id) {
        this.update();
        return;
      }

      this.store();
    },
    addOption() {
      if (!this.newOption || !this.item.windows_event_log) return;
      this.item.windows_event_log.types.push(this.newOption);
      this.newOption = "";
    },
    handleUploadedAgents() {
      if (!this.version) {
        console.error("missing version");
        return;
      }
      this.isSavingVersion = true;
      var formData = new FormData();
      formData.append("linux_64", this.$refs.linux64.files[0]);
      formData.append("linux_32", this.$refs.linux32.files[0]);
      formData.append("windows_64", this.$refs.windows64.files[0]);
      formData.append("windows_32", this.$refs.windows32.files[0]);
      this.$http
        .post("/api/v1/agent/version?version=" + this.version, formData)
        .then((body) => {
          if (!body || !body.data) {
            return;
          }

          this.$refs.addVersionForm.reset();
          this.$store.dispatch("NOTIFY", {
            type: "success",
            text: body.data.message,
          });
          this.isSavingVersion = false;
          this.createVersionModal = false;
        })
        .catch((e) => {
          this.isSavingVersion = false;
        });
    },
    create() {
      this.item = {
        enabled: true,
        windows_event_log: {
          enabled: false,
        },
        file_log: {
          enabled: false,
        },
        resource_monitor: {
          enabled: false,
          interval: 10,
        },
      };
      this.modal = true;
      this.expanded = false;
    },
    edit(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.modal = true;
      this.expanded = false;
    },
    loadSourceTypes() {
      return this.$http.get("/api/v1/source-types/names").then((body) => {
        if (!body || !body.data || !body.data.data) {
          return;
        }

        this.source_types = body.data.data;
      });
    },
    loadIndexes() {
      return this.$http.get("/api/v1/indexes/names").then((body) => {
        if (!body || !body.data || !body.data.data) {
          return;
        }

        this.indexes = body.data.data;
      });
    },
    items(filter, order, pagination) {
      return this.$http
        .get("/api/v1/forwarders", {
          params: {
            filter: filter.query,
            sort: order.by,
            order: order.order,
            page: pagination.page,
            limit: pagination.perPage,
          },
        })
        .then((body) => {
          if (!body || !body.data) {
            return {};
          }

          return body.data.data;
        });
    },
    fetchAgents(filter, order, pagination) {
      return this.$http
        .get("/api/v1/agents", {
          params: {
            filter: filter.query,
            sort: order.by,
            order: order.order,
            page: pagination.page,
            limit: pagination.perPage,
          },
        })
        .then((body) => {
          if (!body || !body.data) {
            return {};
          }

          return body.data.data;
        });
    },
    fetchVersions(filter, order, pagination) {
      return this.$http
        .get("/api/v1/agent/version", {
          params: {
            filter: filter.query,
            sort: order.by,
            order: order.order,
            page: pagination.page,
            limit: pagination.perPage,
          },
        })
        .then((body) => {
          if (!body || !body.data) {
            return {};
          }

          return body.data.data;
        });
    },
    store() {
      this.item.resource_monitor.interval = parseInt(
        this.item.resource_monitor.interval
      );

      return this.$http.post("/api/v1/forwarders", this.item).then((body) => {
        if (!body || !body.data) {
          return;
        }

        this.closeModal();
        this.$store.dispatch("NOTIFY", {
          type: "success",
          text: body.data.message,
        });

        this.$refs.table.loadItems();
      });
    },
    update() {
      this.item.resource_monitor.interval = parseInt(
        this.item.resource_monitor.interval
      );

      return this.$http
        .patch(`/api/v1/forwarders/${this.item.id}`, this.item)
        .then((body) => {
          if (!body || !body.data) {
            return;
          }

          this.closeModal();
          this.$store.dispatch("NOTIFY", {
            type: "success",
            text: body.data.message,
          });

          this.$refs.table.loadItems();
        });
    },
    destroy(item) {
      if (!window.confirm(this.$t("rus"))) {
        return;
      }

      return this.$http.delete(`/api/v1/forwarders/${item.id}`).then((body) => {
        if (!body || !body.data) {
          return;
        }

        this.$store.dispatch("NOTIFY", {
          type: "success",
          text: body.data.message,
        });

        this.$refs.table.loadItems();
      });
    },
  },
};
</script>
<style lang="scss">
.forwarders-download {
  svg {
    margin: 0 5px -3px 0;
    fill: #363636;
    width: 16px;

    .dashboard.is-dark & {
      fill: white;
    }
  }
}
</style>
